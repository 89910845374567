import React from "react";
import classNames from "classnames";
import { isMobileBrowser } from "../commons/HelperFunctions";
import TrustPilotWidget from "./TrustPilot/TrustPilotWidget";
import { GDLogo } from "./GDLogo";
import classes from "./TopBanner.module.scss";

const GD_LOGO_WIDTH = 150;

type TopBannerProps = {
  color: string;
  trustPilotMobileAltLayout: boolean;
};

function TopBanner({ color, trustPilotMobileAltLayout }: TopBannerProps) {
  const isMobile = isMobileBrowser();

  return (
    <div
      id="topBanner"
      className={ classNames(
        classes.topBanner,
        trustPilotMobileAltLayout && classes.wrapTrustPilot
      ) }
    >
      <GDLogo width={ GD_LOGO_WIDTH } color={ color } isMobile={ isMobile } />
      <TrustPilotWidget isMobile={ isMobile } />
    </div>
  );
}

export default TopBanner;
