import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";
import { EventType, QueryParam, System } from "../commons/Constants";
import { postEvent } from "../commons/EventHelper";
import { createBaseLanderEvent } from "../commons/LanderEvent";
import { Settings } from "../config";
import { LanderConfig } from "../types/LanderConfig";
import { VerizonContent } from "../verizon/VerizonContent";
import { AdSense } from "./utils/adSenseUtils";
import loadAdsense from "./utils/loadAdsense";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="contentBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div id={ adsenseContentDivId }>
        <FormattedMessage id="contentBlockedText" />
      </div>
      {search}
    </>
  );
}

export function AdContent({ page }: { page: string }) {
  const [loadingAdsense, setLoadingAdsense] = useState(false); // Make sure we only load it once
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState(true);
  const { landerConfig, queryConfig, landerConfigDispatch } = useLanderConfig();

  useEffect(() => {
    if (!landerConfig.isForcedVerizon && !loadingAdsense) {
      setLoadingAdsense(true);
      loadAdsense(landerConfig, queryConfig, page, (success) => {
        if (!success && landerConfig.experiment?.key === "parking_cp_ab_01") {
          landerConfigDispatch({
            type: LanderConfigAction.UPDATE_CHANNEL_BY_TREATMENT,
            payload: landerConfig?.experiment?.cohort === "on",
          });
        }

        setIsAdsenseSuccess(success);
      });
    }
  }, [
    landerConfig,
    page,
    queryConfig,
    setIsAdsenseSuccess,
    landerConfigDispatch,
    loadingAdsense,
    setLoadingAdsense,
  ]);

  if (landerConfig.isForcedVerizon) {
    return <VerizonContent />;
  }

  if (isAdsenseSuccess) {
    return (
      <AdSenseBlock
        landerConfig={ landerConfig }
        queryConfig={ queryConfig }
        page={ page }
      />
    );
  }

  // If adsense fails...

  if (landerConfig.system === System.PARKWEB) {
    redirectToSerp(landerConfig.lander.banner?.link, landerConfig);
  } else if (
    landerConfig.experiment.key === "parking_cp_ab_01" &&
    !!landerConfig.experiment.data.useSerpForAdsenseFail
  ) {
    // Used for parking_cp_ab_01 treatment cohort,
    // - The CP api doesn't return a serp link, so we need to construct it
    // - The control cohort doesn't need a serp link at all. So we can hardcode utm_campaign to track treatment "..._b_001"
    const serpLink =
      `https://www.godaddy.com/domainsearch/find?` +
      `key=parkweb&utm_source=godaddy&utm_medium=parkedpages&utm_campaign=x_dom-other_parkedpages_x_x_invest_b_001&` +
      `tmskey=dpp_dbs&domainToCheck=${landerConfig.domain.rootDomain}&itc=namefind_redirect`;
    redirectToSerp(serpLink, landerConfig);
  }

  return <VerizonContent />;
}

function redirectToSerp(serpLink: string, landerConfig: LanderConfig) {
  postEvent(Settings.EVENT_PUBLISH_API, {
    ...createBaseLanderEvent(EventType.SERP_REDIRECT, landerConfig),
    serpLink,
  });
  window.location.assign(serpLink);
}
